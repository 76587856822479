* {
    margin: 0;
    padding: 0;
}

.signinContainer {
    /* border: 1px solid red; */
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5vw; /* Changed from 20px to 2.5vw */
    margin: auto;
}

.logoContainer {
    /* border: 1px solid blue; */
    width: 90%;
    height: 43.5vw; /* Changed from 600px to 37.5vw */
}

.appLogo {
    width: 100%;
    height: 44.5vw; /* Changed from 600px to 37.5vw */
    margin-top: 2.5vw; /* Changed from 20px to 2.5vw */
    border-top-left-radius: 3.125vw; /* Changed from 50px to 3.125vw */
    border-top-right-radius: 3.125vw; /* Changed from 50px to 3.125vw */
    margin-left: 1.25vw; /* Changed from 10px to 1.25vw */
}

.signinContainer_leftSection {
    width: 100%;
    /* border: 1px solid yellow; */
}

.signinButton {
    width: 100%!important; /* Changed from 545px to 31.875vw */

    margin-top: 0.3125vw!important; /* Changed from 5px to 0.3125vw */
    margin-bottom: 1.25vw!important; /* Changed from 20px to 1.25vw */
    background-color: #E33F3B!important;
    color: white!important;
    font-weight: bold!important;
    font-size: 1.5vw!important; /* Changed from 18px to 1.125vw */
}

.helloText {
    font-family: Raleway;
    font-size: 3.9375vw; /* Changed from 42px to 3.9375vw */
    font-weight: 700;
    line-height: 3.5625vw; /* Changed from 38px to 3.5625vw */
    letter-spacing: 0em;
    text-align: left;
    background-image: linear-gradient(95.28deg, #253D7E 0%, #01AEEF 99.09%);
    -webkit-background-clip: text;
    /* Clip text to the background */
    color: transparent;
    position: absolute;
    left: 52.0208vw; /* Changed from 750px to 13.0208vw */
    top: 8.3333vw; /* Changed from 100px to 8.3333vw */
}

.emailContainer {
    /* border: 1px solid black; */
    position: absolute;
    left: 52.0208vw; /* Changed from 750px to 13.0208vw */
    top: 24vw; /* Changed from 300px to 25vw */
}

.submitButton {
    background-color: rgb(0, 81, 255);
    color: white;
    margin-right: 1.0417vw; /* Changed from 10px to 1.0417vw */
    font-weight: bold;
}

.cancelButton {
    background-color: rgb(207, 207, 214);
    color: rgb(26, 25, 25);
    margin-right: 1.0417vw; /* Changed from 10px to 1.0417vw */
    font-weight: bold;
}
.forgotPasswordLink{
        text-decoration: none;
        color: #01AEEF;
        cursor: pointer;
      
}
@media (max-width: 912px) {
    .signinContainer {
        /* border: 1px solid green; */
        width: 100%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: auto;
        gap: 7.5vw; /* Changed from 20px to 2.5vw */
        margin: auto;
    }
    .appLogo {
        width: 90%;
        position: absolute;
        left: 4vw;
        height: 80vw;
    }
    .helloText {
        left: 6.125vw; /* Changed from 50px to 3.125vw */
        top: 85.6667vw; /* Changed from 650px to 41.6667vw */
        font-size: 5vw;
    }
    .signinContainer_leftSection {
        /* width: 100%; */
        /* border: 1px solid yellow; */
        height: 80vw;
    }

    .emailContainer {
        width: 90%;
        left: 5vw; /* Changed from 30px to 1.875vw */
        top: 105vw; /* Changed from 800px to 50vw */
    }
    .emailInput,.passwordInput{
        margin-bottom: 3vw!important;
    }
    .emailInput label{ 
        font-size: 3.5vw!important;
    }
    .passwordInput label{ 
        font-size: 3.5vw!important;
    }

    .signinButton {
        font-size: 3.5vw!important; /* Changed from 18px to 1.125vw */
        margin-bottom: 3vw!important;
        height: 10vw;
    }
    .forgotPasswordLink{
      font-size: 4vw
}
}

@media all and (min-width: 250px) and (max-width: 545px) {
    .signinContainer {
        /* border: 1px solid green; */
        width: 100%;
        /* height: 150px; */
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: auto;
        gap: 2.5vw; /* Changed from 20px to 2.5vw */
        margin: auto;
    }

    .appLogo {
        width: 90%;
        position: absolute;
        left: 3vw;
        height: 80vw;
    }

    .helloText {
        font-size: 5vw;
        left: 6.125vw; /* Changed from 50px to 3.125vw */
        top: 85.6667vw; /* Changed from 650px to 41.6667vw */
    }

    .emailContainer {
        width: 90%;
        left: 4vw; /* Changed from 30px to 1.875vw */
        top: 115vw; /* Changed from 800px to 50vw */
    }

    .signinButton {
        width: 31.875vw; /* Changed from 545px to 31.875vw */
        height: 3.75vw; /* Changed from 60px to 3.75vw */
        margin-top: 0.3125vw; /* Changed from 5px to 0.3125vw */
        margin-bottom: 1.25vw; /* Changed from 20px to 1.25vw */
        background-color: #E33F3B;
        color: white;
        font-weight: bold;
        font-size: 1.125vw; /* Changed from 18px to 1.125vw */
    }

    .emailInput label{ 
        font-size: 5vw!important;
    }
    .passwordInput label{ 
        font-size: 5vw!important;
    }
    .forgotPasswordLink{
      font-size: 5vw
}
.signinButton {
    height: 15vw!important;
    font-size: 5vw!important; /* Changed from 18px to 1.125vw */
    margin-bottom: 15px!important;
}
}
