
*{
    margin: 0;
    padding: 0;
}


/* Display Admin Users  Css*/
.displayUsersCard{
    /* border: 1px solid red; */
        width: 75%;
        overflow: hidden;
        padding: 12px;
        margin-left: 20vw;
        margin-top: 10vw;
};
.searchBar{
   
}
.searchBar{
    /* border: 1px solid green; */
        width: 25vw;
        position: absolute;
        right: -47vw;
        top: 2vw;
}

.addUserButton{
    position: absolute; 
    right: 6vw; 
    top: 12vw;
}
@media (max-width: 768px) {
    .displayUsersCard{
        /* border: 1px solid red; */
            width: 85%;
            overflow: hidden;
            padding: 12px;
            margin-left: 10vw;
            margin-top: 17vw;
    };
    .searchBar{
        width: 25vw;
        position: absolute;
        right: -47vw;
        top: 2vw;
    }
   
}
@media (max-width: 1210px) {
.displayUsersCard{
    /* border: 1px solid red; */
        width: 85%;
        overflow: hidden;
        padding: 12px;
        margin-left: 10vw;
        margin-top: 17vw;
};
.searchBar{
    width: 25vw;
    position: absolute;
    right: 6vw;
    top: 8vw;
}
.searchBar{
    width: 25vw;
    position: absolute;
    right: -58vw;
        top: 2vw;
}
.addUserButton{
    position: absolute; 
    right: 6vw; 
    top: 21vw;
}

}