.navbar{
    background-color: #ffffff;
    color: #2f2f2f;
    position: fixed;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}
.resetTitle{
font-weight: bold;
font-size: 2vw;
}
.resetDialogBox{
    width: 100%;

}
.resetOldPasswordInput{

}
.resetNewPasswordInput{
    margin-top: 2vw;
    margin-bottom: 2vw;

}
.resetConfirmPasswordInput{

}
.resetPasswordCancelBtn{
    margin-right: 1.3vw!important;
    background-color: rgb(207, 207, 214)!important;
    color: rgb(26, 25, 25)!important;
    font-size: 1vw!important;
    font-weight: bold!important;
}
.resetPasswordContinueBtn{
    margin-right: 1.2vw!important;
    font-weight: bold!important;
    background-color: rgb(0, 100, 255)!important;
    color: white!important;
    font-size: 1vw!important;
}
.profileCard{
        width: 550px!important;
        height: 300px!important;
        display: flex!important;
        flex-direction: column!important;
        align-items: start!important;
    
}
.nameInputBox{
    margin-top: 2vw!important;
    width: 100%!important;
}
.emailInputBox{
    margin-top: 3vw!important;
    width: 100%!important;
}
.mobileInputBox{
    margin-top: 3vw!important;
    width: 100%!important;
}
.profileCancelBtn{
    margin-right: 1.3vw!important;
    background-color: rgb(207, 207, 214)!important;
    color: rgb(26, 25, 25)!important;
    font-size: 1vw!important;
    font-weight: bold!important;
}
.profileContinueBtn{
    margin-right: 2vw!important;
    font-weight: bold!important;
    background-color: rgb(0, 100, 255)!important;
    color: white!important;
    font-size: 1vw!important;
}


@media (max-width: 912px) {
    .resetTitle{
        font-weight: bold;
        font-size: 5vw!important;
        }
        .resetDialogBox{
            width: 90%!important;
        height: 35vw!important;
        }
        .resetOldPasswordInput label,.resetNewPasswordInput label,.resetConfirmPasswordInput label{
            font-size: 3.5vw!important;
        
        }
        .resetNewPasswordInput{
            margin-top: 5vw!important;
            margin-bottom: 5vw!important;
        
        }
        .resetConfirmPasswordInput{
        
        }
        .resetPasswordCancelBtn{
            margin-right: 1.3vw!important;
            background-color: rgb(207, 207, 214)!important;
            color: rgb(26, 25, 25)!important;
            font-size: 3vw!important;
            font-weight: bold!important;
        }
        .resetPasswordContinueBtn{
            margin-right: 1.5vw!important;
            font-weight: bold!important;
            background-color: rgb(0, 100, 255)!important;
            color: white!important;
            font-size: 3vw!important;
        }
        .profileCard{
            width: 550px!important;
            height: 300px!important;
            display: flex!important;
            flex-direction: column!important;
            align-items: start!important;
        
    }
    .profileDialogTitle{
        font-weight: bold!important;
        font-size: 3.5vw!important;
    }
    .nameInputBox label,.emailInputBox label,.mobileInputBox label{
        font-size: 3vw!important;
        font-weight: bold!important;
    }
    .nameInputBox value,.emailInputBox value,.mobileInputBox value{
        font-size: 10vw!important;
        /* font-weight: bold; */
    }
    .nameInputBox{
        margin-top: 2vw!important;
        width: 100%!important;
    }
    .emailInputBox{
        margin-top: 3vw!important;
        width: 100%!important;
    }
    .mobileInputBox{
        margin-top: 3vw!important;
        width: 100%!important;
    }
    .profileCancelBtn{
        margin-right: 1.3vw!important;
        font-size: 3vw!important;
    }
    .profileContinueBtn{
        margin-right: 2vw!important;
        font-size: 3vw!important;
    }
}

@media all and (min-width: 250px) and (max-width: 545px) {
    .resetTitle{
        font-weight: bold!important;
        font-size: 6vw!important;
        }
        .resetDialogBox{
            width: 90%!important;
        
        }
        .resetOldPasswordInput{
            width: 100%!important;
        
        }
        .resetNewPasswordInput{
            width: 100%!important;
            margin-top: 2vw!important;
            margin-bottom: 2vw!important;
        
        }
        .resetConfirmPasswordInput{
            width: 100%!important;
        
        }
        .resetOldPasswordInput label,.resetNewPasswordInput label,.resetConfirmPasswordInput label{
            font-size: 4vw!important;
        
        }
        .profileCard{
            width: 550px!important;
            height: 220px!important;
            display: flex!important;
            flex-direction: column!important;
            align-items: start!important;
        
    }
    .profileDialogTitle{
        font-weight: bold!important;
        font-size: 5vw!important;
    }
    .nameInputBox label,.emailInputBox label,.mobileInputBox label{
        font-size: 5vw!important;
        font-weight: bold!important;
    }
    .nameInputBox value,.emailInputBox value,.mobileInputBox value{
        font-size: 10vw!important;
        /* font-weight: bold; */
    }
    .nameInputBox{
        margin-top: 3vw!important;
        width: 50%!important;
    }
    .emailInputBox{
        margin-top: 5vw!important;
        width: 50%!important;
    }
    .mobileInputBox{
        margin-top: 5vw!important;
        width: 50%!important;
    }
    .profileCancelBtn{
        margin-right: 1.3vw!important;
        font-size: 3vw!important;
    }
    .profileContinueBtn{
        margin-right: 2vw!important;
        font-size: 3vw!important;
    }
}