.totalUsersCard{
 background-color: #238548; 
 margin-top: 16vw;
 margin-left: 15vw;
 width:30%;
};

@media (max-width: 912px) {
    .totalUsersCard{
        margin-top: 40vw;
        margin-left: 27vw;
        width:70%;
       };
}

@media all and (min-width: 250px) and (max-width: 545px) {
    .totalUsersCard{
        margin-top: 40vw;
        margin-left: 27vw;
        width:70%;
       };
}