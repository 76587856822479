
*{
    margin: 0;
    padding: 0;
}


/* Display Admin Users  Css*/
.appUsersCard{
    /* border: 1px solid red; */
        width: 80%!important;
        overflow: hidden!important;
        padding: 12px!important;
        margin-left: 16vw!important;
        margin-top: 10vw!important;
};
.appUsersList{
   padding: 20px;
}
.searchBoxAppUsers{
    /* border: 1px solid green; */
        width: 25vw!important;
        position: absolute!important;
        right: 3vw!important;
        /* top: 17vw!important; */
}

.tableRow{
    min-width: 100px!important;
    font-weight: bold!important;
    background-color: rgb(0,100,225)!important;
    color: white!important;
}
@media (max-width: 820px) {
    .appUsersCard{
        /* border: 1px solid red; */
            width: 85%!important;
            overflow: hidden!important;
            padding: 12px!important;
            margin-left: 9vw!important;
            margin-top: 17vw!important;
    };
    .appUsersList{
   padding: 20px;
    }
    .searchAppBoxUsers{
        /* border: 1px solid green; */
            width: 40vw!important;
            position: absolute!important;
            right: 5vw!important;
            /* top: 20vw!important; */
            
    }

    .tableRow{
        min-width: 100px!important;
        font-weight: bold!important;
        background-color: rgb(0,100,225)!important;
        color: white!important;
        font-size: 2.5vw!important;
}
.tableColumn{
    font-size: 2.5vw!important;
}
}

@media all and (min-width: 250px) and (max-width: 545px) {
    .appUsersCard{
        /* border: 1px solid red; */
            width: 76%!important;
            overflow: hidden!important;
            padding: 12px!important;
            margin-left: 16vw!important;
            margin-top: 22vw!important;
    };
    .usersList{
   
    }
    .searchBoxAppUsers{
        /* display: none!important; */
            width: 47vw!important;
            position: absolute!important;
            /* right: 6vw!important; */
            top: 27vw!important;
            
    }
    
    .tableRow{
        min-width: 100px!important;
        font-weight: bold!important;
        background-color: rgb(0,100,225)!important;
        color: white!important;
        font-size: 3.3vw!important;
}
.tableColumn{
    font-size: 3.3vw!important;
}
}

