
*{
    margin: 0;
    padding: 0;
}


/* Display Admin Users  Css*/
.displayNotificationsCard{
    /* border: 1px solid red; */
        width: 80%!important;
        overflow: hidden!important;
        padding: 12px!important;
        margin-left: 16vw!important;
        margin-top: 10vw!important;
};

.searchBox{
        width: 25vw !important;
        position: absolute !important;
        right: 5.5vw !important;
        top: 12vw !important;
}

.addNewButton{
    position: absolute!important; 
    right: 5.5vw!important; 
    top: 14vw!important;
    background-color: rgb(0, 100, 255)!important;
     color: white!important;
   font-weight: bold!important;
              
}
.archivedDeleted{
    position: absolute!important; 
    right: 5.0vw!important; 
    top: 17vw!important;
              
}
.tableRow{
        min-width: 100px!important;
        font-weight: bold!important;
        background-color: rgb(0,100,225)!important;
        color: white!important;
}
.tableRow1{
    width: 40vh!important;
    font-weight: bold!important;
    background-color: rgb(0,100,225)!important;
    color: white!important;
}
.tableColumn{
   
}
@media (max-width: 820px) {
    .displayNotificationsCard{
        /* border: 1px solid red; */
            width: 85%!important;
            overflow: hidden!important;
            padding: 12px!important;
            margin-left: 9vw!important;
            margin-top: 17vw!important;
    };
    .searchBox{
        width: 35vw!important;
        position: absolute!important; 
        right: 6vw!important; 
        top: 20vw!important;
    } 
    
    .addNewButton{
        position: absolute!important; 
        right: 6vw!important; 
        top: 28vw!important;
        background-color: rgb(0, 100, 255)!important;
         color: white!important;
       font-weight: bold!important;
       font-size: 2.2vw!important;
                  
    }
    .tableRow{
        min-width: 100px!important;
        font-weight: bold!important;
        background-color: rgb(0,100,225)!important;
        color: white!important;
        font-size: 2.5vw!important;
}
.tableColumn{
    font-size: 2.5vw!important;
}
}


@media all and (min-width: 250px) and (max-width: 545px) {
    .displayNotificationsCard{
        /* border: 1px solid red; */
            width: 75%!important;
            overflow: hidden!important;
            padding: 12px!important;
            margin-left: 17vw!important;
            margin-top: 17vw!important;
    };
    
    .searchBox{
        width: 70vw!important;
        position: absolute!important; 
        left: 23vw!important; 
        top: 45vw!important;
    } 
    
    .addNewButton{
        position: absolute!important; 
        right: 6vw!important; 
        top: 26vw!important;
        background-color: rgb(0, 100, 255)!important;
         color: white!important;
       font-weight: bold!important;
       font-size: 3vw;
                  
    }
    .tableRow{
        min-width: 100px!important;
        font-weight: bold!important;
        background-color: rgb(0,100,225)!important;
        color: white!important;
        font-size: 3.3vw!important;
}
.tableColumn{
    font-size: 3.3vw!important;
}

}