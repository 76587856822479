
*{
    margin: 0;
    padding: 0;
}


/* Display Admin Users  Css*/
.displayUsersCard{
    /* border: 1px solid red; */
        width: 75%;
        overflow: hidden;
        padding: 12px;
        margin-left: 20vw;
        margin-top: 10vw;
};
.requiredField {
    color: red;
  }
.searchBar{
   
}
.searchBar{
    /* border: 1px solid green; */
        width: 25vw;
        position: absolute;
        right: -47vw;
        top: 2vw;
}
.request{
    position: absolute!important;
    left: 0vw!important;
}

.addUserButton{
    position: absolute!important; 
    right: 6vw!important; 
    top: 12vw!important;
}
.notificationTitle{
    display: flex!important;
    justify-content: center!important;
    text-align: center!important;
}
.sendButton{
    width: 40%!important;
       position: absolute!important;
       right: 2vw!important;
       background-color: rgb(0, 100, 255)!important;
        color: white!important;
        font-weight: bold!important;
   
}
.sendButton:hover{
    background-color: rgb(0, 100, 255)!important;
    color: white!important;
    font-weight: bold!important;
}
.cancelButton{
    width: 40%!important;
    position: absolute!important;
    left: 2vw!important;
     color: red!important;
     font-weight: bold!important;
     border: 2px solid red!important;

}
.cancelButton:hover{
    border: 2px solid red!important;
}
@media (max-width: 768px) {
    .displayUsersCard{
        /* border: 1px solid red; */
            width: 85%;
            overflow: hidden;
            padding: 12px;
            margin-left: 10vw;
            margin-top: 17vw;
    };
    .searchBar{
        width: 25vw;
        position: absolute;
        right: -47vw;
        top: 2vw;
    }
   
}
@media (max-width: 1210px) {
.displayUsersCard{
    /* border: 1px solid red; */
        width: 85%;
        overflow: hidden;
        padding: 12px;
        margin-left: 10vw;
        margin-top: 17vw;
};
.searchBar{
    width: 25vw;
    position: absolute;
    right: 6vw;
    top: 8vw;
}
.searchBar{
    width: 25vw;
    position: absolute;
    right: -58vw;
        top: 2vw;
}
.addUserButton{
    position: absolute; 
    right: 6vw; 
    top: 21vw;
}

}